
// stylelint-disable unit-whitelist
$breakpoints_legacy: (
  phone: 320px,
  tablet: 768px,
  desktop: 1024px,
  invitelist: 1266px,
  desktoplarge: 1440px
);
// stylelint-enable unit-whitelist

/**
 * helper function to convert an rem value into a pixel unit
 * This is base on the change font-size on the html to 62,5% (= 10px).
 */
@function rem-to-px($remValue) {
  @return ($remValue / 1rem) * 10px;
}
